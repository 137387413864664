<template>
  <div class="detail-page">
    <div class="detail-header">
      <div class="box-top-left">
        <img @click="back" src="@/assets/img/zhang/back.png" alt="" />
        <span>{{ detailsData.name }}</span>
      </div>
      <div class="box-top-right">
        <div
          class="box-top-right-btn4"
          v-if="detailsData.isPublicPlan == 0 && detailsData.status != 2"
          @click="startSpecialActions"
        > 
          更新
        </div> 
        <div
          class="box-top-right-btn2"
          v-if="detailsData.isPublicPlan == 0 && detailsData.status == 2"
          @click="closeSpecialActions"
        >
          关闭
        </div>
          <el-button 
         class="box-top-right-btn3" 
         @click="exportWordIng" 
         :disabled="exportNoClick">
          生成报告
        </el-button>
      
        <div
          class="box-top-right-btn1"
          @click="openError(detailsData)"
          v-if="detailsData.isPublicPlan == 0"
        >
          删除
        </div>
      </div>
    </div>
    <div class="page">
      <Breadcrumb :routerList="routerList"></Breadcrumb>
      <div class="box-content m-t-16">
        <div class="box-content-top">
          <div style="display: flex">
            <div
              v-for="(item, inx) in tag"
              :key="inx"
              @click="jump(item,inx)"
              :class="inx == tagInx ? 'Selectli' : 'NoSelect'"
            >
              {{ item.name }}
            </div>
          </div>
        </div>
        <Generalization @getExportTimeIfOk="getExportTimeIfOk" v-show="tagInx == 0"></Generalization>
        <Information v-show="tagInx == 1"></Information>
        <!-- <component ref="dataAnalysis" :is="name"></component> -->
      </div>
    </div>
    <!-- 删除二次确认框 -->
    <deleteDialog ref="openDialog" @deleteFn="deleteSpecialActions"></deleteDialog>
    <!-- 导出进度条 -->
    <ExportDialog ref="exportDialog"></ExportDialog>
  </div>
</template>

<script>
import Breadcrumb from "@/components/breadcrumb.vue";
import Generalization from "./page/generalization.vue";
import Information from "./page/information.vue";
import Special from "@/api/special/index";
import deleteDialog from "@/components/deleteDialog.vue";
import ExportDialog from "@/components/exportDialog.vue";

export default {
  inject: ["reload"],
  components: {
    Breadcrumb,
    Generalization,
    Information,
    deleteDialog,
    ExportDialog,
  },
  data() {
    return {
      routerList: ["专项行动", "信息详情"], //面包屑
      name: this.$store.state.activeNameSpecial,
      tag: [
        {
          name: "事件概况",
          value: "generalization",
        },
        {
          name: "全部信息",
          value: "information",
        }, 
      ],
      // 详情数据
      detailsData: {},
      eventOverview: "", //事件概括
      exportNoClick: true, //导出按钮禁用
      tagInx:0
    };
  },
  methods: {
    // 获取导出时间是否ok
    getExportTimeIfOk(value) {
      console.log(value)
    this.exportNoClick = value
    },
    //生成报告
    async exportWordIng() {
      console.log(this.$store.state.dataListTopic);
      if(!this.$store.state.dataListTopic.mediaScatter)return this.$message.warning('数据加载完毕,正在准备截图,请稍后再次点击重试')
      if(!this.$store.state.dataListTopic.mediaStatistics)return this.$message.warning('数据加载完毕,正在准备截图,请稍后再次点击重试')
      if(!this.$store.state.dataListTopic.publishTrend)return this.$message.warning('数据加载完毕,正在准备截图,请稍后再次点击重试')
      if(!this.$store.state.dataListTopic.topicSurvey)return this.$message.warning('数据加载完毕,正在准备截图,请稍后再次点击重试')
      if(!this.$store.state.dataListTopic.wordCloud)return this.$message.warning('数据加载完毕,正在准备截图,请稍后再次点击重试')
      if(!this.$store.state.dataListTopic.wordCloudList)return this.$message.warning('数据加载完毕,正在准备截图,请稍后再次点击重试')
      this.$refs.exportDialog.exportVisible = true;
      this.$refs.exportDialog.downloadProgress();
      this.$store.state.dataListTopic.topicId = this.detailsData.id / 1;
      const res = await Special.exportWordIng(this.$store.state.dataListTopic);
      if (res.data.code == 200) {
        let link = document.createElement("a");
        link.href = res.data.data;
        link.click();
        link.remove();
        clearTimeout(this.$refs.exportDialog.timer);
        this.$refs.exportDialog.wite = false;
        this.$refs.exportDialog.percentage = 100;
      }
    },
    // 删除
    async deleteSpecialActions(item) {
      const res = await Special.deleteSpecialActions(item.item.id);
      if (res.data.code == 200) {
        this.$message.success("删除成功！");
        // this.$router.push({
        //   path: "/special",
        // });
        window.close()
      }
    },
    // 打开删除弹窗
    openError(item) {
      item.message = "此条专项行动数据";
      this.$refs.openDialog.openDialog(item);
    },
    // 关闭专项行动
    async closeSpecialActions() {
      const res = await Special.closeSpecialActions(this.detailsData.id);
      if (res.data.code == 200) {
        this.$message.success("关闭专项行动成功！");
        this.detailsData.status = 4;
        this.$router.push({
          path: "/topicListDetails",
          query: {
            info: encodeURIComponent(
              JSON.stringify({
                id: this.detailsData.id,
                title: this.detailsData.name,
                item: this.detailsData,
              })
            ),
          },
        });
        this.reload();
      }
    },
    // 更新(开启)
    async startSpecialActions() {
      const res = await Special.startSpecialActions(this.detailsData.id);
      if (res.data.code == 200) {
        this.$message.success("更新成功！");
        this.detailsData.status = 2;
        this.$router.push({
          path: "/topicListDetails",
          query: {
            info: encodeURIComponent(
              JSON.stringify({
                id: this.detailsData.id,
                title: this.detailsData.name,
                item: this.detailsData,
              })
            ),
          },
        });
        this.reload();
      }
    },
    back() {
      this.$router.push("/special");
    },
    jump(item,inx) {
      this.name = item.value;
      this.tagInx = inx;
      sessionStorage.setItem("activeNameSpecial", item.value);
    },
    // 获取数据列表
    async getDetailsDataList() {
      const res = await Special.getDetailsDataList({
        page: 1,
        limit: 10,
        relationId: this.detailsData.id / 1,
      });
      if (res.data.code == 200) {
        if (res.data.data.list.length == 0) {
          this.exportNoClick = true;
        } else {
          this.exportNoClick = false;
          this.exportNoClick = true;

        }
      } else {
        this.exportNoClick = true;
      }
    },
  },
  created() {
    console.log(this.$store.state.dataListTopic)
    let params = JSON.parse(decodeURIComponent(this.$route.query.info));
    if (params) {
      this.detailsData = params.item;
    }
    this.getDetailsDataList();
  },
  destroyed() {
    this.$store.state.activeName = "generalization";
    sessionStorage.removeItem("activeNameSpecial");
  },
};
</script>

<style lang="scss" scoped>
.detail-page {
  width: 100%;
  display: flex;
  flex-direction: column;
  .detail-header {
    width: 100%;
    height: 56px;
    background-color: #ffffff;
    display: flex;
    justify-content: space-between;
    position: sticky;
    z-index: 10;
    top: 80px;
    .box-top-left {
      display: flex;
      align-items: center;
      img {
        width: 38px;
        height: 38px;
        margin: 9px 15px 9px 23px;
        cursor: pointer;
      }
      span {
        font-size: 20px;
        font-weight: 600;
        line-height: 56px;
        font-family: PingFangSC-Semibold, PingFang SC;
      }
    }
    .box-top-right {
      display: flex;
      align-items: center;
      margin-right: 25px;
      div {
        box-sizing: border-box;
        padding: 6px 24px;
        font-weight: 700;
        font-size: 14px;
        margin-left: 20px;
        border-radius: 4px;
        cursor: pointer;
      }
      .box-top-right-btn4 {
        background: #e1fef4;
        color: #02bc7c;
      }
      .box-top-right-btn1 {
        background: #fff6f7;
        color: #ea3342;
      }
      .box-top-right-btn3 {
        background: #e9f4ff;
        color: #2e59ec;
        box-sizing: border-box;
        padding: 8px 24px;
        font-weight: 700;
        font-size: 14px;
        margin-left: 20px;
        border-radius: 4px;
        border: none;
      }
      .box-top-right-btn2 {
        background: #fdf6ef;
        color: #ff8000;
      }
    }
  }
  .page {
    padding-top: 16px;
    .box-content {
      padding: 0 0 24px 0;
      .box-content-top {
        width: 100%;
        height: 54px;
        background: #ffffff;
        border-radius: 4px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-left: 48px;
        padding-right: 16px;
        font-size: 16px;
        color: #333333;
        margin-bottom: 16px;

        .playback {
          display: flex;
          height: 32px;
          align-items: center;
          color: #ffffff;

          img {
            width: 14px;
            height: 14px;
            margin-right: 3px;
            position: relative;
            top: 3px;
          }
        }

        .sentimentPy {
          background: linear-gradient(304deg, #00bbdd 0%, #21dbb8 100%);
        }

        .sentimentFx {
          background: linear-gradient(114deg, #16aef3 0%, #027ffe 100%);
        }

        .Selectli {
          line-height: 54px;
          margin-right: 80px;
          color: #f4630b;
          border-bottom: 2px solid #f4630b;
          cursor: pointer;
          font-weight: bold;
        }

        .NoSelect {
          line-height: 54px;
          margin-right: 80px;
          cursor: pointer;
        }
      }
    }
  }
}
</style>
