<template>
  <div class="box1">
    <!-- 事件概况 -->
    <div class="box1-div1">
      <div class="box1-div1-top">
        <img src="@/assets/img/icon/juxing.png" alt />
        <span>事件概况</span>
      </div>
      <div class="box1-div1-center">
        <!-- v-html="incidentOverview" -->
        <div v-html="eventOverview"></div>
      </div>
    </div>
    <!-- 信息数量 -->
    <div class="box1-div2">
      <div class="box1-div2-top">
        <img src="@/assets/img/icon/juxing.png" alt />
        信息数量 {{ inforNum }}（今日新增
        <span style="color: #02bc7c; margin-left: 5px">
          <img src="@/assets/img/icon/top.png" alt />
          {{ inforTodNum }}
        </span>
        ）
        <span style="font-size: 14px; color: #999999">增量为当日0点到现在</span>
      </div>
      <div class="box1-div2-center">
        <div class="box1-div2-center-left" id="mediaScatter">
          <!-- <div class="box1-div2-center-left-title">各平台发布数据</div> -->
          <ul v-if="infoListData && infoListData.length > 0" class="box1-div2-center-left-table">
            <li v-for="(item, index) in infoListData" :key="index">
              <div class="box1-div2-center-left-table-left">
                {{ item.showname }}
              </div>
              <div class="box1-div2-center-left-table-right" @click="mediaTypeSearch(item)">
                {{ item.totalComments }}（
                <img src="@/assets/img/icon/top.png" alt />
                <span>{{ item.todayComments }}</span>
                ）
              </div>
            </li>
          </ul>
          <ul class="box1-div2-center-left-table" v-else>
            <li class="box1-div2-center-left-table-noData">
              <p style="text-align: center; width: 100%">暂无数据</p>
            </li>
          </ul>
        </div>
        <!-- 769 -->
        <div class="box1-div2-center-right-box" id="mediaStatistics">
          <div class="box1-div2-center-right">
            <div v-if="inforListTotal && inforListTotal.length > 0">
              <div style="height: 250px" ref="echartsone" id="echartsone"></div>
              <p style="color: #b8bcc3; font-size: 14px; margin-top: 50px">
                各环节整体分布
              </p>
            </div>
            <div class="analyse-container" v-else>
              <div class="analyse-container-img">
                <img src="@/assets/img/gjh/weitu.png" />
                <p>各环节整体分布</p>
              </div>
            </div>
          </div>
          <div class="box1-div2-center-right">
            <div v-if="inforListToday && inforListToday.length > 0">
              <div style="height: 250px" ref="echartstwo" id="echartstwo"></div>
              <p style="color: #b8bcc3; font-size: 14px; margin-top: 50px">
                各环节今日新增分布
              </p>
            </div>
            <!-- inforListToday -->
            <div class="analyse-container" v-else>
              <div class="analyse-container-img">
                <img src="@/assets/img/gjh/weitu.png" />
                <p>各环节今日新增分布</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 发布趋势 -->
    <div class="box1-div3">
      <div class="box1-div3-top">
        <img src="@/assets/img/icon/juxing.png" alt="" />
        <span style="font-weight: bold">
          发布趋势
        </span>
        <!-- 默认时间单位 -->
        <el-select v-model="timeInterval" placeholder="请选择时间单位" size="medium" style="margin: 0px 20px" @change="timeUnit" clearable>
          <el-option label="年度" value="1"></el-option>
          <el-option label="季度" value="2"></el-option>
          <el-option label="月份" value="3"></el-option>
          <el-option label="星期" value="4"></el-option>
          <el-option label="天" value="5"></el-option>
          <el-option label="小时" value="6"></el-option>
        </el-select>
        <!-- 设置时间范围 -->
        <el-date-picker v-model="time" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" @change="topicProgressEcharts"
                        value-format="yyyy-MM-dd HH:mm:ss" style="width: 400px" size="medium" :default-time="['00:00:00', '23:59:59']"></el-date-picker>
      </div>
      <div class="box1-div3-center" id="publishTrend">
        <div id="topicProgressEcharts" v-show="controlReleaseTrend"></div>
        <div class="topicNoData" v-show="!controlReleaseTrend">
          <img src="@/assets/img/gjh/weitu.png" alt="" />
          <p>暂无数据</p>
        </div>
      </div>
    </div>
    <!-- 关键词云 -->
    <div class="box1-div6">
      <div class="box1-div6-top">
        <div class="box1-div6-top-left">
          <img src="@/assets/img/icon/juxing.png" alt="" />
          关键词云
        </div>
        <div class="box1-div6-top-right" @click="WordsCloudSet">
          <span class="el-icon-s-tools">&nbsp;&nbsp;热词设置</span>
        </div>
      </div>
      <div class="box1-div6-content">
        <div class="box1-div6-content-left">
          <div id="wordsCloudEcharts" class="wordsCloudEcharts" v-show="tagsAll.length > 0"></div>
          <div v-show="tagsAll.length == 0" style="text-align: center; width: 100%; margin-top: 100px">
            <img src="@/assets/img/gjh/weitu.png" alt="" />
            <p>暂无数据</p>
          </div>
        </div>
        <div class="box1-div6-content-right" id="wordCloudList">
          <el-table :data="tableWordsCloud" tooltip-effect="dark" :header-cell-style="headerStyle"
                    style="width: 100%; text-align: center; border: 1px solid #e4e6ec" :cell-style="cellStyle" stripe>
            <el-table-column type="index" label="排名" width="228"></el-table-column>
            <el-table-column prop="keyword" label="关键词" width="228"></el-table-column>
            <el-table-column prop="count" label="词频数"></el-table-column>
          </el-table>
        </div>
      </div>
    </div>
    <!-- 关键词云弹窗 -->
    <div class="dialog">
      <el-dialog title="热词设置" :visible.sync="WordCloudDialog" width="33%">
        <div class="allWords">
          <div id="allWords-one" style="margin-bottom: 40px">
            <el-tag v-for="(tag, index) in tagsAll" :key="index" closable disable-transitions @close="delAllWords(index, tag)">
              {{ tag.name }}
            </el-tag>
          </div>
          <span style="margin-left: 10px; color: black; font-weight: bold">屏蔽热词</span>
          <div id="allWords-two" style="margin-top: 10px">
            <el-tag v-for="(tag, index) in tagsDel" :key="index" closable disable-transitions @close="delShieldWords(index, tag)">
              {{ tag.name }}
            </el-tag>
          </div>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button class="cancal_z" @click="WordCloudDialog = false">取 消</el-button>
          <el-button class="search_z" type="primary" @click="saveWordsCloud">确 定</el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import "echarts-wordcloud";
import Special from "@/api/special/index";
import Error from "@/api/error/index";
import html2canvas from "html2canvas";

export default {
  data() {
    return {
      series: [],
      //事件进展
      eventLineList: "",
      //禁止选择未来时间
      pickerOptions: this.pickerOptionsObj(),
      // 时间选择范围
      time: null,
      timeInterval: "",
      /////////////////////////////////
      // 事件概况
      eventOverview: "",
      // 词云数据
      tableWordsCloud: [],
      // 缓存屏蔽词云
      cacheTagsDel: [],
      // 缓存所有词云
      cacheTagsAll: [],
      // 弹窗词云标签
      tagsAll: [],
      // 弹窗屏蔽词云
      tagsDel: [],
      // 设置弹窗显示
      WordCloudDialog: false,
      // 表格头部样式
      headerStyle: {
        background: "#F6F7FA",
        textAlign: "center",
        fontSize: "14px",
        color: "#333",
      },
      // 表格主体样式
      cellStyle: {
        textAlign: "center",
      },
      infoListData: [],
      inforListTotal: [], //存echart里面的全部信息数据
      inforListToday: [],
      inforNum: "", //信息总数
      inforTodNum: "", //今日新增
      // 详情数据id
      detailedDataId: null,
      // 控制发布趋势显示
      controlReleaseTrend: true,
    };
  },
  methods: {
    // 获取发布趋势数据
    async getReleasetrend() {
      let data = {
        relationId: this.detailedDataId,
      };
      this.timeInterval ? (data.minTimeUnit = this.timeInterval / 1) : "";
      this.time ? ((data.startTime = this.time[0]), (data.endTime = this.time[1])) : "";
      const res = await Special.getReleasetrend(data);
      if (res.data.code == 200) {
        if (res.data.data.length == 0) {
          this.getEcharts3([]);
          this.controlReleaseTrend = false;
        } else {
          this.getEcharts3(res.data.data);
          this.controlReleaseTrend = true;
        }
      }
    },
    // 发布趋势echarts
    getEcharts3(res) {
      let myChart = this.$echarts.init(document.getElementById("topicProgressEcharts"));
      let dataX = [];
      let dataLine = [];
      this.series = [];
      if (res.length != 0) {
        res.forEach((item) => {
          dataX.push(item.date);
          dataLine.push(item.total);
        });
        for (let i = 0; i < 21; i++) {
          this[`data${i}`] = [];
          this[`dataName${i}`] = res[0].dateDetails[i].platformTypeName;
          res.forEach((v) => {
            this[`data${i}`] = [...this[`data${i}`], v.dateDetails[i].total];
          });
          let seriesData = {
            type: "bar",
            name: this[`dataName${i}`],
            stack: "sum",
            barWidth: 6,
            data: this[`data${i}`],
          };
          this.series.push(seriesData);
          if (i == 20) {
            let seriseLine = {
              type: "line",
              name: "总体增量",
              data: dataLine,
              color: "#FF509D",
            };
            this.series.push(seriseLine);
          }
        }
      }
      let option;
      option = {
        tooltip: {
          trigger: "axis",
          show: true,
          axisPointer: {
            type: "shadow",
            shadowStyle: {
              color: "rgba(0, 0, 0, 0.03)",
            },
          },
        },
        grid: {
          left: "3%",
          top: "16%",
          right: "3%",
          bottom: "12%",
        },
        legend: {
          data: ["总体增量"],
          x: "center",
          y: "0",
        },
        xAxis: {
          data: dataX,
          axisTick: {
            show: false,
          },
        },
        yAxis: {
          splitLine: {
            show: true,
            lineStyle: {
              type: "dashed",
            },
          },
        },
        series: this.series,
      };
      option && myChart.setOption(option);
      setTimeout(() => {
        let reg = new RegExp("data:image/png;base64,", "g");
        html2canvas(document.querySelector("#publishTrend")).then((resolve) => {
          let imgUrl = resolve.toDataURL("image/png");
          imgUrl = imgUrl.replace(reg, "");
          this.$store.state.dataListTopic.publishTrend = imgUrl;
        });
      }, 1000);
    },
    // 日期选择范围
    pickerOptionsObj() {
      let _this = this;
      return {
        disabledDate(time) {
          if (
            time > new Date(_this.minTime.getTime() - 24 * 60 * 60 * 1000) &&
            time <= _this.maxTime
          ) {
            return false;
          } else {
            return true;
          }
        },
      };
    },
    //日期选择
    topicProgressEcharts() {
      this.getReleasetrend();
    },
    // 时间单位选择
    timeUnit() {
      this.getReleasetrend();
    },
    // 获取信息数量
    async getSpecialActionsInformation(data) {

      const res = await Special.getSpecialActionsInformation(data);
      if (res.data.code == 200) {
        this.inforNum = res.data.data.count;
        this.inforTodNum = res.data.data.todayCount;

        this.infoListData.forEach((x) => {
          res.data.data.data.forEach((y) => {
            if (x.showname == y.keyText) {
              x.totalComments = y.cnt;
              x.todayComments = y.todayCount;
            }
          });
        });
        this.infoListData.forEach((item) => {
          let objA = {
            name: "",
            value: "",
          };
          objA.name = item.showname;
          objA.value = item.totalComments;
          if (objA.value > 0) {
            this.inforListTotal.push(objA);
          }
          let objB = {
            name: "",
            value: "",
          };
          objB.name = item.showname;
          objB.value = item.todayComments;
          if (objB.value > 0) {
            this.inforListToday.push(objB);
          }
        });
        setTimeout(() => {
          
          this.setInfoEcharts("echartsone", this.inforListTotal);
          this.setInfoEcharts("echartstwo", this.inforListToday);
        }, 100);
      }
      // this.$emit('getExportTimeIfOk', true)
    },
    // 信息数量echarts
    setInfoEcharts(name, EchartsArr) {
      const myChart = this.$echarts.init(document.getElementById(name));
      let option = {
        color: [
          "#009CFC",
          "#11DAE9",
          "#21CA63",
          "#FFC75C",
          "#FF855B",
          "#FE6299",
          "#802EEC",
          "#2E59EC",
        ],
        series: [
          {
            type: "pie",
            center: ["50%", "50%"],
            radius: 70,
            minAngle: 10,
            itemStyle: {
              borderWidth: 1,
              borderColor: "#fff",
            },
            label: {
              formatter: "{b} {@整体}",
              overflow: "breakAll",
            },
            data: EchartsArr,
          },
        ],
      };
      myChart.setOption(option);
      setTimeout(() => {
        let reg = new RegExp("data:image/png;base64,", "g");
        html2canvas(document.querySelector("#mediaScatter")).then((resolve) => {
          let imgUrl = resolve.toDataURL("image/png");
          imgUrl = imgUrl.replace(reg, "");
          this.$store.state.dataListTopic.mediaScatter = imgUrl;
        });
        html2canvas(document.querySelector("#mediaStatistics")).then((resolve) => {
          let imgUrl = resolve.toDataURL("image/png");
          imgUrl = imgUrl.replace(reg, "");
          this.$store.state.dataListTopic.mediaStatistics = imgUrl;
        });
        this.$emit('getExportTimeIfOk', false)
      }, 5000);
    },
    // 获取事件概括
    async getSpecialActionsGeneralization(data) {
      const res = await Special.getSpecialActionsGeneralization(data);
      if (res.data.code == 200) {
        this.eventOverview = res.data.data;
        this.$store.state.dataListTopic.topicSurvey = this.eventOverview;
      }
    },
    // 获取词云数据
    async getWordsCloudData() {
      const res = await Special.getWordsCloudData({
        relationId: this.detailedDataId,
      });
      if (res.data.code == 200) {
        if (res.data.code == 200) {
          res.data.data.data = res.data.data.data.map(item => {
            const keyword = Object.keys(item)[0]; // 获取键名
            const count = item[keyword]; // 获取键值
            return { keyword, count }; // 返回包含 value 和 key 属性的对象
          });
          this.tableWordsCloud = res.data.data.data.slice(0, 10);
          res.data.data.data.forEach((item) => {
            this.tagsAll.push({
              name: item.keyword,
              value: item.count,
            });
          });
          this.cacheTagsAll = this.deepClone(this.tagsAll);
          if (res.data.data.excludeWordCloud) {
            res.data.data.excludeWordCloud.split("|").forEach((x) => {
              this.tagsDel.push({
                name: x,
              });
            });
          }
          this.cacheTagsDel = this.deepClone(this.tagsDel);
          this.wordsCloudEcharts();
        } else {
          this.tableWordsCloud = [];
          this.tagsAll = [];
        }
      }
    },
    // 词云echarts
    wordsCloudEcharts() {
      let myChart = this.$echarts.init(document.getElementById("wordsCloudEcharts"));
      let option = {
        series: [
          {
            type: "wordCloud",
            //用来调整词之间的距离
            gridSize: 16,
            sizeRange: [16, 50],
            rotationRange: [0, 0],
            //随机生成字体颜色
            textStyle: {
              color: function () {
                return (
                  "rgb(" +
                  Math.round(Math.random() * 255) +
                  ", " +
                  Math.round(Math.random() * 255) +
                  ", " +
                  Math.round(Math.random() * 255) +
                  ")"
                );
              },
            },
            left: "center",
            top: "center",
            right: null,
            bottom: null,
            width: "200%",
            height: "200%",
            //数据
            data: this.tagsAll,
          },
        ],
      };
      myChart.setOption(option);
      setTimeout(() => {
        let reg = new RegExp("data:image/png;base64,", "g");
        html2canvas(document.querySelector("#wordCloudList")).then((resolve) => {
          let imgUrl = resolve.toDataURL("image/png");
          imgUrl = imgUrl.replace(reg, "");
          this.$store.state.dataListTopic.wordCloudList = imgUrl;
        });
        html2canvas(document.querySelector("#wordsCloudEcharts")).then((resolve) => {
          let imgUrl = resolve.toDataURL("image/png");
          imgUrl = imgUrl.replace(reg, "");
          this.$store.state.dataListTopic.wordCloud = imgUrl;
        });
      }, 1000);
    },
    // 保存修改词云数据
    async saveWordsCloud() {
      this.WordCloudDialog = false;
      let data = {
        relationId: this.detailedDataId,
      };
      if (this.tagsDel.length > 0) {
        data.excludeWordCloud = this.tagsDel.map((x) => x.name).join("|");
      }
      const res = await Special.setWordsCloudData(data);
      if (res.data.code == 200) {
        if (res.data.code == 200) {
          this.tagsDel = [];
          this.tagsAll = [];
          this.getWordsCloudData();
          this.$message.success("词云数据修改成功！");
        }
      }
    },
    //删除所有词云中的标签
    delAllWords(index, tag) {
      this.tagsAll.splice(index, 1);
      this.tagsDel.push(tag);
    },
    // 删除屏蔽中的标签
    delShieldWords(index, tag) {
      this.tagsDel.splice(index, 1);
      this.tagsAll.push(tag);
    },
    // 词云设置弹窗
    WordsCloudSet() {
      this.tagsDel = this.deepClone(this.cacheTagsDel);
      this.tagsAll = this.deepClone(this.cacheTagsAll);
      this.WordCloudDialog = true;
    },
    // 随机数
    randomNum() {
      let arr = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];
      let sum = "";
      for (let i = 0; i < 3; i++) {
        let s = arr[parseInt(Math.random() * arr.length)];
        sum += s;
      }
      if (sum.charAt(0) == "0") {
        sum = "100";
      }
      return Number(sum);
    },
    // 随机数
    randomNumTwo() {
      let arr = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];
      let sum = "";
      for (let i = 0; i < 2; i++) {
        let s = arr[parseInt(Math.random() * arr.length)];
        sum += s;
      }
      if (sum.charAt(0) == "0") {
        sum = "10";
      }
      return Number(sum);
    },
    //深拷贝方法
    deepClone(target) {
      // 定义一个变量
      let result;
      // 如果当前需要深拷贝的是一个对象的话
      if (typeof target === "object") {
        // 如果是一个数组的话
        if (Array.isArray(target)) {
          result = []; // 将result赋值为一个数组，并且执行遍历
          for (let i in target) {
            // 递归克隆数组中的每一项
            result.push(this.deepClone(target[i]));
          }
          // 判断如果当前的值是null的话；直接赋值为null
        } else if (target === null) {
          result = null;
          // 判断如果当前的值是一个RegExp对象的话，直接赋值
        } else if (target.constructor === RegExp) {
          result = target;
        } else {
          // 否则是普通对象，直接for in循环，递归赋值对象的所有值
          result = {};
          for (let i in target) {
            result[i] = this.deepClone(target[i]);
          }
        }
        // 如果不是对象的话，就是基本数据类型，那么直接赋值
      } else {
        result = target;
      }
      // 返回最终结果
      return result;
    },
    // 获取平台类型
    async getPlatformList() {
      const res = await Error.getPlatformList();
      if (res.data.code == 200) {
        res.data.data.forEach((x) => {
          let obj = {
            showname: "",
            totalComments: "",
            todayComments: 0,
          };
          obj.showname = x.label;
          this.infoListData.push(obj);
        });
        this.getSpecialActionsInformation(this.detailedDataId);
      }
    },
  },
  mounted() { },
  created() {
    let params = JSON.parse(decodeURIComponent(this.$route.query.info));
    if (params) {
      this.detailedDataId = params.id / 1;
    }
    this.getPlatformList();
    this.getWordsCloudData();
    this.getReleasetrend();
    this.getSpecialActionsGeneralization(this.detailedDataId);
  },
};
</script>

<style lang="scss" scoped>
.box1 {
  .box1-div1 {
    width: 100%;
    background: #ffffff;
    border-radius: 4px;

    .box1-div1-top {
      display: flex;
      align-items: center;
      font-size: 16px;
      font-weight: bold;
      color: #333333;
      padding: 24px 16px;
    }

    .box1-div1-center {
      padding: 0 44px 32px;

      div {
        width: 100%;
        background: #f9f9fc;
        border-radius: 8px;
        padding: 38px 40px;
        font-size: 14px;
        color: #333333;
        line-height: 28px;
      }
    }
  }
  .box1-div2 {
    width: 100%;
    background: #ffffff;
    border-radius: 4px;
    margin-top: 16px;

    .box1-div2-top {
      display: flex;
      align-items: center;
      font-size: 16px;
      font-weight: bold;
      color: #333333;
      padding: 24px 16px;
    }

    .box1-div2-center {
      min-height: 220px;
      display: flex;
      justify-content: space-between;
      padding: 0 44px 24px;

      .box1-div2-center-left {
        width: 50%;

        span {
          color: #02bc7c;
          font-size: 14px;
        }

        .box1-div2-center-left-title {
          height: 24px;
          font-size: 20px;
          font-weight: bold;
          color: #2e59ec;
        }

        .box1-div2-center-left-table {
          font-size: 14px;
          margin-top: 33px;
          width: 100%;
          background: #ffffff;
          border: 1px solid #e4e6ec;
          display: flex;
          flex-wrap: wrap;

          li {
            display: flex;
            flex: 1;
            height: 40px;
            line-height: 40px;
            text-align: center;
            background: #f5f6fa;

            .box1-div2-center-left-table-left {
              width: 150px;
              text-align: center;
              border-right: solid 2px #fff;
              border-left: solid 2px #fff;
            }

            .box1-div2-center-left-table-right {
              width: 230px;
              text-align: center;
              cursor: pointer;
            }
          }

          li:nth-child(3),
          li:nth-child(4),
          li:nth-child(7),
          li:nth-child(8),
          li:nth-child(11),
          li:nth-child(12),
          li:nth-child(15),
          li:nth-child(16),
          li:nth-child(19),
          li:nth-child(20),
          li:nth-child(23),
          li:nth-child(24),
          li:nth-child(27),
          li:nth-child(28) {
            background: #ffffff;
          }

          li:nth-child(1),
          li:nth-child(3),
          li:nth-child(5),
          li:nth-child(7) {
            .box1-div2-center-left-table-left {
              border-left: none;
            }
          }
        }
      }

      .box1-div2-center-right {
        width: 50%;
        box-sizing: border-box;
        padding-top: 100px;
        text-align: center;

        #echartsone {
          width: 100%;
          height: 100%;
        }

        #echarts2 {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
  .box1-div3 {
    width: 100%;
    background: #ffffff;
    border-radius: 4px;
    margin-top: 16px;
    .box1-div3-top {
      display: flex;
      align-items: center;
      font-size: 16px;
      color: #333333;
      padding: 24px 16px;
    }
    .box1-div3-center {
      display: flex;
      justify-content: space-between;
      padding: 0 44px 24px;
      #topicProgressEcharts {
        height: 375px;
        width: 100%;
        padding: 24px 40px;
      }
      .topicNoData {
        text-align: center;
        padding: 20px 0;
        height: 375px;
        width: 100%;
        box-sizing: border-box;
        padding-top: 80px;
        p {
          font-size: 14px;
          color: #999999;
        }
      }
    }
  }
  .box1-div6 {
    width: 100%;
    background: #ffffff;
    border-radius: 4px;
    margin-top: 16px;

    .box1-div6-top {
      padding: 24px 16px;
      display: flex;
      justify-content: space-between;

      .box1-div6-top-left {
        display: flex;
        align-items: center;
        font-size: 16px;
        font-weight: bold;
        color: #333333;

        img {
          position: relative;
          top: 1px;
        }

        div {
          display: flex;
          align-items: center;
          font-size: 14px;
          font-weight: bold;
          color: #2e59ec;
          margin-left: 24px;
          cursor: pointer;
        }
      }
      .box1-div6-top-right {
        color: #2e59ec;
        cursor: pointer;
        margin-right: 20px;
        span {
          font-size: 14px;
          font-weight: bold;
        }
      }
    }

    .box1-div6-content {
      padding: 0px 110px 0px 16px;
      padding-bottom: 25px;
      display: flex;
      box-sizing: border-box;
      justify-content: space-evenly;
      width: 100%;

      .box1-div6-content-left {
        min-width: 45%;
        min-height: 450px;
        margin-top: 50px;
        .wordsCloudEcharts {
          width: 700px;
          height: 450px;
        }
        p {
          font-size: 14px;
          color: #999999;
        }
      }

      .box1-div6-content-right {
        width: 46%;
        height: 100%;
      }
    }
  }
  .dialog {
    .allWords {
      box-sizing: border-box;
      padding: 20px;
      #allWords-one {
        .el-tag {
          background-color: #eff7ff;
          height: 33px;
          font-size: 14px;
          min-width: 73px;
          text-align: center;
          line-height: 33px;
          margin-left: 11px;
          margin-right: 11px;
          margin-top: 10px;
          border: none;
          color: #2d5ae2;
          ::v-deep .el-tag__close {
            color: #2d5ae2;
          }
        }
      }

      #allWords-two {
        .el-tag {
          background-color: #fff3f5;
          height: 33px;
          font-size: 14px;
          min-width: 73px;
          text-align: center;
          line-height: 33px;
          margin-left: 11px;
          margin-right: 11px;
          margin-top: 10px;
          border: none;
          color: #e5444e;
          ::v-deep .el-tag__close {
            color: #e5444e;
          }
        }
      }
    }
  }
  .box1-div4-center .el-table::before {
    left: 0;
    bottom: 0;
    height: 0;
  }
  .box-div-span {
    padding: 10px 0;
    display: inline-block;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    line-height: 2;
  }
  .box-div-title {
    height: 40px;
    line-height: 40px;
  }
  .analyse-container {
    width: 100%;
    height: 250px;
    background: #ffffff;
    border-radius: 4px;
    margin-top: 20px;
    text-align: center;
  }
  .analyse-container-img {
    position: relative;
    top: 40%;
    transform: translateY(-50%);
    img {
      width: 80%;
    }
  }
  .analyse-container-img p {
    font-size: 14px;
    color: #999999;
  }
  .box1-div2-center-right-box {
    width: 769px;
    display: flex;
  }
}
</style>
